.newCV {
  width: 100%;

  .newCV_container {
    box-shadow: var(--primary-box-shadow);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    padding: 4rem 15rem;
  }
}
@media screen and (max-width: 1200px) {
  .newCV {
    .newCV_container {
      padding: 4rem 1rem;
    }
  }
}
