.employees {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  .employees_header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem 8rem 0 8rem;
    .employees_header_text {
      font-size: 30px;
      font-weight: 450;
      color: var(--hover-color);
      letter-spacing: 0.04em;
    }
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 7rem;
      width: 90%;
      height: 2px;
      border-radius: 4px;
      background-color: #e6e6e6;
    }
  }
  .employees_container {
    padding: 2rem 8rem 3rem 8rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // flex-direction: row;
    flex-wrap: wrap;
    background: transparent;
    background-clip: content-box;
    border-radius: 8px;
    gap: 2.5rem;
    flex-direction: column;
    .employee_card_cotainer_body {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      background-clip: content-box;
      flex-direction: row;
      gap: 1rem;
    }
    .users_box {
      width: 100%;
      height: 700px;
      flex-grow: 1;
      display: flex;
      .users_table {
        border: none;
        box-shadow: none;
        .users_table_row {
          width: 99.8%;
          border-radius: 8px;
          margin-bottom: 0.5rem;
          border: 1px solid transparent;
          outline: none;
          transition: var(--primary-transition);
          &:hover {
            background-color: transparent;
            border: 1px solid var(--hover-color);
            .edit_icon {
              color: var(--primary-color);
            }
            .delete_icon {
              color: #f93154;
            }
            .password_icon {
              color: black;
            }
          }
          .edit_icon {
            color: transparent;
            width: 17px;
            margin-left: 0.5rem;
            margin-right: 1rem;
            cursor: pointer;
            transition: var(--primary-transition);
            &:hover {
              color: var(--hover-color);
              scale: 1.2;
            }
          }
          .delete_icon {
            color: transparent;
            width: 17px;
            margin-left: 0.5rem;
            margin-right: 1rem;
            cursor: pointer;
            transition: var(--primary-transition);
            &:hover {
              color: red;
              scale: 1.2;
            }
          }
          .password_icon {
            color: transparent;
            width: 17px;
            margin-left: 0.5rem;
            margin-right: 1rem;
            cursor: pointer;
            transition: var(--primary-transition);
            &:hover {
              color: black;
              scale: 1.2;
            }
          }
          .exp_tooltip {
            max-width: 300px !important;
            background-color: var(--background-color) !important;
            box-shadow: var(--primary-box-shadow) !important;
            border-radius: 5px !important;
            padding: 1rem !important;
            color: black !important;
            text-align: center !important;
            line-height: 1.5 !important;
            opacity: 1 !important;
          }
        }
        .users_table_cell {
          outline: none;
        }
        .MuiDataGrid-columnHeaders {
          border: none;
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .employees {
    .employees_container {
      padding: 2rem 1rem;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 1200px) {
  .employees {
    .employees_header {
      padding: 2rem 1rem 0 1rem;
      padding-bottom: 0.5rem;
      &::before {
        left: 0;
        width: 100%;
      }
    }
    .employees_container {
      padding: 2rem 1rem;
      justify-content: center;
    }
  }
}
