@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
.invoice_element {
  padding: 0 1.5rem 0 2.5rem;
  width: 100%;
  background-color: white;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 5rem;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: var(--primary-transition);
  cursor: pointer;
  .supplier {
    width: 170px !important;
    word-wrap: break-word;
    color: var(--primary-color);
    font-weight: 600;
    letter-spacing: 0.03em;
  }
  &:hover {
    border: 1px solid #008db9;
    scale: 1.035;
  }
  .invoice_element_date {
    width: 140px;
    max-width: 140px;
  }
  .invoice_element_status {
    width: 7.5rem;
    height: 2.8rem;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    font-weight: 600;
    .invoice_element_status_dot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }
}
.paid {
  background-color: #f3fcf9;
}
.paid_dot {
  background-color: green;
}
.pending {
  background-color: #fcfaf3;
}
.pending_dot {
  background-color: orangered;
}
.declined {
  background-color: #fcf3f3;
}
.declined_dot {
  background-color: red;
}

@media screen and (max-width: 650px) {
  .invoice_element {
    .invoice_element_status {
      width: 6.5rem;
    }
    padding: 0 1rem;
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
    .invoice_element_date {
      display: none;
    }
  }
}

@media screen and (min-width: 651px) and (max-width: 820px) {
  .invoice_element {
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 14px;
    }
  }
}
