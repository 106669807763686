.deleteUser {
  height: auto;
}

@media screen and (max-width: 650px) {
  .deleteUser {
    width: 100%;
  }
}

@media screen and (min-width: 651px) and (max-width: 850px) {
  .deleteUser {
    width: 65%;
  }
}
