$bottom-line-color: rgba(128, 128, 128, 0.2);
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$header-color: #4f4f4f;
.deleteCandidate_modal {
  .deleteCandidate_modal_container {
    h2 {
      margin-top: 1.5rem;
    }
    .update_password_inputs {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      gap: 1rem;
      padding-bottom: 1rem;
      form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 1rem;
        .update_password_inputs_section {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 1rem;
          position: relative;
          label {
            width: 100%;
            color: $label-color;
          }
          input {
            border: 1px solid $border-color-required;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            background-color: transparent;

            font-size: 1rem;
            font-weight: 400;
            color: $header-color;
            outline: none;
            box-shadow: none;
            transition: var(--primary-transition);
            &:focus {
              box-shadow: 0 0 10px $border-color-required;
            }
            &:valid {
              border: 1px solid $input-entered;
              &:focus {
                box-shadow: 0 0 10px $input-entered;
              }
            }
          }
        }
        label {
          width: 33%;
          color: $label-color;
        }
      }
    }
    .deleteCandidate_modal_container_buttons {
      .submit_button {
        &:disabled {
          background-color: $border-color-required !important;
          &:hover {
            background-color: red !important;
          }
        }
      }
    }
  }
}
.changePassword {
  height: auto;
}

@media screen and (max-width: 650px) {
  .changePassword {
    .changePassword_container {
      .update_password_inputs {
        form {
          flex-direction: column;
        }
      }
    }
  }
  .changePassword {
    width: 100%;
  }
}

@media screen and (min-width: 651px) and (max-width: 1300px) {
  .changePassword {
    overflow: scroll;
    width: 55%;
    .changePassword_container {
      form {
        gap: 0.2rem;
      }
    }
  }
}
