$bottom-line-color: rgba(128, 128, 128, 0.2);
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$header-color: #4f4f4f;
.education {
  width: 100%;
  padding: 1.5rem 3rem 5rem 3rem;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 15px;
  &::before {
    content: "";
    background: $bottom-line-color;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 90%;
  }
  .education_container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    .education_description {
      width: 30%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
    }
    .education_input_container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: relative;
      padding-bottom: 2rem;
      gap: 1rem;

      &::before {
        content: "";
        background: $bottom-line-color;
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 90%;
      }
    }
  }
}
