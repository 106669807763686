.chart_widget {
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
  background-color: white;
  width: 63%;
  height: 21rem;
  border-radius: 45px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  .chart_widget_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 10%;
    p {
      width: 20%;
    }
  }
}
@media screen and (max-width: 1050px) {
  .chart_widget {
    width: 100%;
    padding: 1.2rem;
    gap: 2px;
    .chart_widget_header {
      p {
        width: 35%;
        font-size: 14px;
      }
    }
  }
}
