$dark-color: #003d50;
.candidate_info_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  cursor: pointer;
  h5 {
    color: $dark-color;
  }
}
