.submit {
  width: 100%;
  padding: 1.5rem 7rem 1.5rem 3rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  position: relative;
  gap: 1rem;
}
