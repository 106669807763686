.displayMonth_modal {
  position: absolute;
  border: 1px solid grey;
  outline: none;
  border-radius: 7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 4px;
  width: 50%;
  background: white;
  position: relative;
  .displayMonth_modal_container {
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    .header {
      font-size: 24px;
      padding: 0.5rem 1.5rem;
      color: var(--primary-color);
    }
    .displayMonth_modal_container_header {
      .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
        width: 60px;
        height: 45px;
      }
      .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
        .dayOfMonth {
          .dot_container {
            .dot {
              background-color: white;
            }
          }
        }
      }
      .css-195y93z-MuiButtonBase-root-MuiPickersDay-root {
        width: 60px;
        height: 45px;
      }
      .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel {
        width: 60px;
      }
      .css-ggw2de-MuiPickersDay-root {
        width: 60px;
        height: 45px;
      }
      .dayOfMonth {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: var(--primary-transition);
        &:hover {
          scale: 1.1;
          .dot {
            background-color: var(--hover-color);
          }
        }
      }
      .dot {
        background-color: var(--primary-color);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5px;
        transition: var(--primary-transition);
      }
    }
    .displayMonth_modal_container_events {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 1rem;
      background-color: rgba(136, 142, 176, 0.2);
      border-radius: 8px;
      padding: 0.6rem;
      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
      .displayMonth_modal_container_event {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        border-radius: 5px;
        .displayMonth_modal_container_event-title-description {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          width: 80%;
          gap: 1rem;
          p {
            &:first-child {
              color: white;
            }
            &:nth-child(2) {
              color: black;
            }
          }
        }
        p {
          color: white;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0.04em;
          &:nth-child(2) {
            color: black;
          }
        }
      }
    }
  }
}
