$header-color: #4f4f4f;
$label-color: rgba(0, 0, 0, 0.6);
$border-color: #e6e6e6;
.invoice_add_from {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  .invoice_add_from_element_container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 2rem;
    .invoice_add_from_element {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      .paymentTerms_element {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background-color: rgba(117, 117, 117, 0.17);
        height: 100%;
        input {
          background-color: transparent !important;
          width: 50px;
          padding: 0;
        }
        .active {
          background-color: white !important;
          padding: 0 5px;
        }
        .disabled {
          background-color: rgba(117, 117, 117, 0.17);
        }
        .paymentTerms_date {
          outline: none;
          border: none;
        }
      }
      label {
        width: 100%;
        color: $label-color;
      }
      input {
        border: 1px solid $border-color;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background-color: transparent;

        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
      }
      input:disabled {
        background-color: rgba(117, 117, 117, 0.17);
      }
    }
  }
}
