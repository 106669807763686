$header-color: #4f4f4f;
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$bottom-line-color: rgba(128, 128, 128, 0.2);
.additional_information {
  width: 100%;
  padding: 1.5rem 3rem 5rem 3rem;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  position: relative;
  gap: 30px;
  &::before {
    content: "";
    background: $bottom-line-color;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 90%;
  }
  .additional_information_body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    .additional_information_body_description {
      width: 30%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
    }
    .additional_information_body_input_container {
      width: 70%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      .additional_information_date {
        .myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
          border-color: green;
        }

        .myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
          border-color: green;
        }
        label {
          color: $header-color;
        }
      }
      .additional_information_input {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        label {
          width: 100%;
          color: $label-color;
        }
        input {
          border: 1px solid $border-color-required;
          padding: 0.375rem 0.75rem;
          border-radius: 0.25rem;
          background-color: transparent;

          font-size: 1rem;
          font-weight: 400;
          color: $header-color;
          outline: none;
          box-shadow: none;
          transition: var(--primary-transition);
          &:focus {
            box-shadow: 0 0 10px $border-color-required;
          }
          &:valid {
            border: 1px solid $input-entered;
            &:focus {
              box-shadow: 0 0 10px $input-entered;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .additional_information {
    .additional_information_body {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .additional_information_body_description {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .additional_information_body_input_container {
        width: 100%;
        input {
          width: 100% !important;
        }
      }
    }
  }
}
