.cancel_button {
  background-color: white;
  color: var(--primary-color);
  border: none;
  border-radius: 10rem;

  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  text-transform: uppercase;

  padding: 0.625rem 1.5rem 0.5rem;
  cursor: pointer;

  transition: var(--primary-transition);
  &:hover {
    color: var(--hover-color);
  }
}
