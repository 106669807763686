$text-color: #6e6d70;
.sort_filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.7rem 1rem;
  background-color: #f7f7f7;
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  color: $text-color;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.03em;
  transition: var(--primary-transition);
  &:hover {
    scale: 1.1;
    background-color: var(--hover-color);
    color: white;
  }
}
.clicked {
  background-color: var(--primary-color);
}
@media screen and (max-width: 650px) {
  .sort_filter-button {
    padding: 0.7rem 0.6rem;
  }
}
