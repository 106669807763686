$bottom-line-color: rgba(128, 128, 128, 0.2);
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$header-color: #4f4f4f;
.general {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-color: #e6e6e6;
    height: 100%;
    width: 1px;
    left: 0;
    top: 0;
  }
  .general_settings_header {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.04em;
    color: var(--primary-color);
  }
  form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .user_input {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      label {
        width: 100%;
        color: $label-color;
        font-size: 14px;
      }
      input {
        border: 1px solid $border-color-required;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background-color: transparent;

        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
        transition: var(--primary-transition);
        &:focus {
          box-shadow: 0 0 10px $border-color-required;
        }
        &:valid {
          border: 1px solid $input-entered;
          &:focus {
            box-shadow: 0 0 10px $input-entered;
          }
        }
      }
      input:disabled {
        background-color: rgba(117, 117, 117, 0.17);
        border: none;
      }
    }
    .update_settings_buttons {
      display: flex;
      width: 100%;
      margin-top: 1.5rem;
    }
  }
}

@media screen and (max-width: 650px) {
  .general {
    form {
      .user_input_row {
        flex-direction: column;
        .user_input {
          input {
            width: 100% !important;
          }
        }
      }
    }
  }
}
