.filter {
  position: absolute;
  top: 2rem;
  left: 0;
  width: 8rem;
  height: 11rem;
  background-color: #f2f2f2;
  box-shadow: var(--primary-box-shadow);
  border-radius: 8px;
  padding: 1rem 0;
  z-index: 2;
  .filter_select_option {
    width: 100%;
    padding: 0.5rem;
    border: none;
    outline: none;
    transition: var(--primary-transition);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    &:hover {
      background-color: #e6e6e6;
      scale: 1.05;
    }
  }
}
@media screen and (max-width: 650px) {
  .filter {
    width: 5.5rem;
  }
}
