@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
$dark-color: #003d50;
.user_dashboard {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 2.5rem 4.5rem;
  .user_dashboard_content {
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2rem;
    font-family: "Raleway", sans-serif;
    padding-right: 1rem;
    h5 {
      font-family: "Raleway", sans-serif;
      font-weight: 800;
      letter-spacing: 0.05em;
      font-size: 26px;
      color: $dark-color;
    }
    .user_dashboard_content_widgets {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      gap: 1.5rem;
      flex-wrap: wrap;
      .user_dashboard_content_widget {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > *:nth-child(2) {
          margin-top: -15px;
        }
        .user_dashboard_content_widget-byod {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          gap: 5px;
          label {
            font-size: 14px;
          }
        }
        .MuiTooltip-popper {
          background-color: red !important;
          margin-left: 0 !important;
        }
      }
      .user_dashboard_content_widget-multisport {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap: 20px;
        margin-top: 15px;
        position: relative;
        & > :first-child {
          margin-right: 20px;
        }
        .multisport_names {
          position: absolute;
          width: 100%;
          height: 100%;
          top: -1.7rem;
          left: 0;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: row;
          span {
            font-size: 12px;
            width: 50%;
            &:nth-child(2) {
              margin-left: 25px;
              width: 55%;
            }
          }
        }
      }
      .user_dashboard_content_widgets-invoice-details {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 1.5rem;
        width: 34%;
      }
    }
  }
  .user_dashboard_calendar {
    width: 20%;
    height: calc(100vh - 147.2px);
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    background-color: #008eb9;
    border-radius: 30px;
    padding: 1.5rem;
    color: white;
    font-family: "Raleway", sans-serif;
  }
}
@media screen and (max-width: 1050px) {
  .user_dashboard {
    flex-direction: column;
    padding: 2.5rem 0.5rem;
    gap: 1rem;
    .user_dashboard_content {
      width: 100%;
      padding-right: 0;
      h5 {
        font-size: 19px;
      }
      .user_dashboard_content_widgets {
        gap: 1rem;
        .user_dashboard_content_widget-multisport {
          margin-left: -10px;
          width: 100%;
          margin-bottom: 5px;
          width: 110%;
          .multisport_names {
            span {
              &:nth-child(2) {
                margin-left: 19px;
              }
            }
          }
        }
        .user_dashboard_content_widgets-invoice-details {
          width: 100%;
        }
      }
    }
  }
}
