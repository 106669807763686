.employee_view_card {
  width: 9.75rem;
  height: 13rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 8px;
  padding: 1rem;
  .employee_view_card_active {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .active {
      width: 10px;
      height: 10px;
      background-color: var(--hover-color);
      border-radius: 50%;
    }
  }
  .employee_view_card_icon {
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(249, 250, 254);
    svg {
      width: 50%;
      height: 50%;
    }
  }
  transition: var(--primary-transition);
  &:hover {
    scale: 1.08;
  }
}
.active_card {
  scale: 1.05;
  background-color: var(--primary-color);
  p {
    color: white;
  }
}
