.anonymize_modal {
  position: absolute;
  border: 1px solid grey;
  outline: none;
  border-radius: 7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 4px;
  width: 50%;
  background: white;
  .anonymize_modal_container {
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .anonymize_modal_form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      .anonymize_modal_form_dropdown {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .anonymize_modal_form_item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
        }
        .anonymize_modal_form_select {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
        }
      }
      .anonymize_modal_form_item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .anonymize_modal {
    width: 100%;
  }
}
