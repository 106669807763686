$bottom-line-color: rgba(128, 128, 128, 0.2);
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$header-color: #4f4f4f;
.updateEvent_modal {
  position: absolute;
  border: 1px solid grey;
  outline: none;
  border-radius: 7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 4px;
  width: 50%;
  background: white;
  position: relative;
  .updateEvent_modal_container {
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    .updateEvent_modal_container_header {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .updateEvent_modal_container_fields {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 1.5rem;
      .user_input {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 0.5rem;
        label {
          width: 100%;
          color: $label-color;
        }
        input {
          border: 1px solid $border-color-required;
          padding: 0.375rem 0.75rem;
          border-radius: 0.25rem;
          background-color: transparent;

          font-size: 1rem;
          font-weight: 400;
          color: $header-color;
          outline: none;
          box-shadow: none;
          transition: var(--primary-transition);
          &:focus {
            box-shadow: 0 0 10px $border-color-required;
          }
          &:valid {
            border: 1px solid $input-entered;
            &:focus {
              box-shadow: 0 0 10px $input-entered;
            }
          }
        }
      }
      .user_input_time {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        gap: 2rem;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .updateEvent_modal {
    width: 80%;
    .updateEvent_modal_container {
      .updateEvent_modal_container_fields {
        .user_input {
          input {
            width: 100% !important;
          }
        }
        .user_input_time {
          flex-direction: column;
          align-items: flex-start;
          .date_picker {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .updateEvent_modal {
    width: 100%;
    .updateEvent_modal_container {
      .updateEvent_modal_container_fields {
        .user_input {
          input {
            width: 100% !important;
          }
        }
        .user_input_time {
          flex-direction: column;
          align-items: flex-start;
          .date_picker {
            width: 100%;
          }
        }
      }
    }
  }
}
