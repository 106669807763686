.navbar {
  width: 100%;
  height: 4.2rem;
  // box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05);
  background-color: var(--primary-color);
  color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem;
  .navbar_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100%;
    gap: 15px;
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }
  .navbar_Links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-direction: row;

    list-style-type: none;
    position: relative;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      width: 110px;
      cursor: pointer;
      transition: var(--primary-transition);
      position: relative;
      &::before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 80%;
        height: 2px;
        background-color: white;
        height: 2px;
        border-radius: 4px;
        scale: 0 1;
        transform-origin: left;
        transition: scale 0.5s;
      }
      &:hover {
        color: var(--hover-color);
        &::before {
          scale: 1;
        }
      }
    }
    .navbar_link {
      font-weight: 400;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      line-height: 28px;
      font-size: 18px;
      .navbar_link_gdpr {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: red;
          color: white;
          font-size: 15px;
          font-weight: bolder;
          padding-left: 1px;
        }
      }
    }
  }
  //small screen
  .navbar_small-screen {
    display: none;
    .navbar_small-screen_overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: var(--primary-color);
      transition: 0.5s ease;
      flex-direction: column;
      z-index: 5;
      .navbar_small-screen_menu_icon_close {
        font-size: 27px;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 10px;
      }
      .navbar_small-screen_overlay_links {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        list-style-type: none;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          cursor: pointer;
          font-size: 25px;
          transition: var(--primary-transition);
          position: relative;
          &::before {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: white;
            height: 2px;
            border-radius: 4px;
            scale: 0 1;
            transform-origin: left;
            transition: scale 0.5s;
          }
          &:hover {
            color: var(--hover-color);
            &::before {
              scale: 1;
            }
          }
          .navbar_link_gdpr {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: row;
            p {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: red;
              color: white;
              font-size: 15px;
              font-weight: bolder;
            }
          }
        }
        .navbar_link {
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 0.04em;
          text-transform: capitalize;
          line-height: 28px;
          font-size: 25px;
        }
      }
    }
  }
  .navbar_small-screen_menu_icon {
    font-size: 27px;
  }
}
//phone query
@media screen and (max-width: 1000px) {
  .navbar {
    .navbar_small-screen {
      display: flex;
    }
    .navbar_Links {
      display: none;
    }
  }
}
@media screen and (max-width: 1150px) {
  .navbar {
    .navbar_Links {
      a {
        font-size: 20px;
      }
    }
  }
}
.open_modal {
  animation: openModal 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.close_modal {
  animation: closeModal 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes openModal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes closeModal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.active {
  color: var(--primary-color);
}
