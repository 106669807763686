.searchbar_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 25%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .searchbar {
    border: none;
    outline: none;
    border-radius: 0.5rem;
    padding: 0.625rem 3rem 0.5rem 1.5rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    line-height: 2;
    font-size: 16px;
    color: grey;
  }
  svg {
    position: absolute;
    font-size: 25px;
    color: grey;
    right: 5%;
  }
}
@media screen and (max-width: 1200px) {
  .searchbar_container {
    transform: translate(-50%, -50%);
    width: 50%;
  }
}
@media screen and (max-width: 650px) {
  .searchbar_container {
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
