$label-color: rgba(0, 0, 0, 0.6);
.addInvoiceButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 1.5rem;
  .submit_invoice {
    padding: 0.5rem 1.7rem;
    border: none;
    border-radius: 20px;
    font-weight: 700;
    letter-spacing: 0.04em;
    font-size: 15px;
    color: white;
    background-color: var(--primary-color);
    transition: var(--primary-transition);
    cursor: pointer;
    &:hover {
      background-color: var(--hover-color);
    }
  }
  .cancel_invoice {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    letter-spacing: 0.04em;
    font-weight: 600;
    color: $label-color;
    cursor: pointer;
    transition: var(--primary-transition);
    &:hover {
      color: var(--hover-color);
    }
  }
}
