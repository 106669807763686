.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .login_container {
    padding: 3rem 3rem;
    height: 31.25rem;
    width: 37.5rem;
    border: none;
    background-color: white;
    word-wrap: break-word;
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.07),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .login_container_header {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      padding-bottom: 0.25rem;
      margin-bottom: 1rem;
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
      h1 {
        font-weight: 500;
        font-size: 2.5rem;
      }
    }
    .login_container_auth-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      h3 {
        font-weight: 400;
        letter-spacing: 0.05em;
        margin-bottom: 2rem;
      }
      .login_container_auth-form_username,
      .login_container_auth-form_password {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        margin-bottom: 1.5rem;
        label {
          position: absolute;
          top: 50%;
          left: 50px;
          transform: translate(-50%, -50%);
          text-transform: capitalize;
          transition: var(--primary-transition);
          color: grey;
        }
        input {
          line-height: 1.5rem;
          font-size: 1rem;
          letter-spacing: 0.05em;
          padding: 0.75em 0.75em;
          outline: none;
          width: 100%;
          z-index: 1;
          background-color: transparent;
          border: none;
          outline: none;
          align-self: flex-start;
          justify-self: flex-start;
          &:focus ~ label {
            top: 0%;
            left: 48px;
            color: var(--primary-color);
            z-index: 2;
          }
          &:valid ~ label {
            top: 0%;
            left: 48px;
            z-index: 2;
          }
        }
      }
      button {
        width: 100%;
        height: 3rem;
        background-color: var(--primary-color);
        color: white;
        border: none;
        font-size: 0.875rem;
        padding: 0.75rem 1.6875rem 0.6875rem;
        cursor: pointer;
        border-radius: 0.3rem;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2),
          0 2px 10px 0 rgba(0, 0, 0, 0.1);
        transition: var(--primary-transition);
        &:hover {
          background-color: var(--hover-color);
        }
      }
    }
  }
}
// telephone on width
@media screen and (min-width: 650px) and (max-width: 900px) {
  .login {
    padding-top: 1rem;
    overflow-y: hidden;
  }
}
