thead {
  width: 100%;
  background-color: rgb(249, 249, 249);
  position: sticky;
  top: 0;
  transition: var(--primary-transition);
  tr {
    th {
      text-align: left;
      padding: 1rem;
      font-size: 16px;
      font-weight: 400;
      .head_filter {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          margin-left: 10px;
          cursor: pointer;
          transition: var(--primary-transition);
          &:hover {
            scale: 1.1;
          }
        }
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: var(--primary-color);
          color: white;
          font-weight: 600;
          margin-bottom: 13px;
        }
      }
    }
  }
}
.scrolled{
  transition: var(--primary-transition);
  background-color: transparent;
  backdrop-filter: blur(13px);
  font-weight: 800;
  font-size: 20px;
  box-shadow: var(--primary-box-shadow);
  &:before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.5px;
    background: var(--primary-color);
    border-radius: 4px;
  }
  tr{
    th{
      color: var(--primary-color);
    }
  }
}