.delete_modal {
  height: auto;
}
@media screen and (max-width: 1200px) {
  .delete_modal {
    width: 75%;
  }
}
@media screen and (max-width: 650px) {
  .delete_modal {
    width: 100%;
  }
}
