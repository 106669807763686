$shadow: rgb(72 84 159 / 10%) 0px 10px 10px -10px;
$primaryColor: #008db9;
$hoverColor: #0dcaf0;
$buttonHover: #0dcaf044;
.invoiceDetails_body {
  width: 100%;
  padding: 1.5rem;
  background-color: white;
  box-shadow: $shadow;
  gap: 3rem;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .invoiceDetails_body_items {
    width: 100%;
    border-radius: 8px;
    table {
      width: 100%;
      border-spacing: 0px;
      background: rgb(249, 250, 254);
      thead {
        display: table-header-group;
        background: rgb(249, 250, 254);
        color: rgb(136, 142, 176);
        font-size: 0.6875rem;
        line-height: 1.63;
        letter-spacing: 0.23px;
        tr {
          th {
            padding: 2rem 0 2rem 2rem;
          }
        }
      }
      tbody {
        font-size: 0.75rem;
        font-weight: bold;
        tr {
          td {
            padding: 0 0 2rem 2rem;
          }
        }
      }
      tfoot {
        background-color: #008db9;
        tr {
          td {
            border: none;
            padding: 2rem 0 2rem 2rem;
            color: white;
            font-weight: bold;
            letter-spacing: 0.23px;
            text-align: start;
          }
          :nth-child(1) {
            font-size: 1.2rem;
          }
          :last-child {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .invoiceDetails_body {
    .invoiceDetails_body_info {
      justify-content: center;
      gap: 1.2rem;
    }
    .invoiceDetails_body_items {
      table {
        padding-top: 2rem;
        thead {
          display: none;
        }
        tbody {
          tr {
            td {
              font-size: 0.75rem;
            }
            :nth-child(2),
            :nth-child(3) {
              display: none;
            }
          }
        }
        tfoot {
          tr {
            :nth-child(2),
            :nth-child(3) {
              display: none;
            }
            :nth-child(1) {
              font-size: 0.6875rem;
            }
            :last-child {
              display: flex;
              font-size: 1.2rem;
              margin-right: 5px;
            }
          }
        }
      }
    }
    h4 {
      font-size: 0.9375rem;
    }
  }
}
