.sort_view {
  position: absolute;
  top: 3.5rem;
  right: 0rem;
  background-color: #f2f2f2;
  box-shadow: var(--primary-box-shadow);
  border-radius: 8px;
  width: 15rem;
  height: 12.5rem;
  z-index: 2;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  .sort_view_container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    .option_button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      padding: 0.4rem 0.5rem;
      .button_text {
        color: var(--primary-color);
        font-weight: 500;
        letter-spacing: 0.03em;
      }
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      transition: var(--primary-transition);
      border-radius: 8px;

      &:hover {
        scale: 1.05;
        background-color: var(--hover-color);
        .button_text {
          color: white;
        }
      }
      .option_button_circle {
        width: 1.3rem;
        height: 1.3rem;
        background-color: white;
        border-radius: 50%;
        .check_icon {
          stroke: white;
          stroke-width: 1.5;
          color: white;
          margin-right: 10px;
          font-size: 22px;
        }
      }
      .active_circle {
        background-color: #01dfc5;
        color: white !important;
      }
    }
    .active_button {
      background-color: var(--primary-color);
      scale: 1.05;
      .button_text {
        color: white;
      }
    }
  }
}
