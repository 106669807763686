.primary_button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 10px;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.375rem 1rem 0.3125rem;
  text-transform: uppercase;
  transition: var(--primary-transition);
  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
}
