.employee_view {
  position: absolute;
  right: 5rem;
  top: 5rem;
  width: 25rem;
  height: 20rem;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: var(--primary-box-shadow);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem;
  z-index: 3;
  p {
    font-size: 18px;
  }
  gap: 1rem;
  .employee_view_container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 1.5rem;
  }
}
@media screen and (max-width: 650px) {
  .employee_view {
    right: 0rem;
  }
}
