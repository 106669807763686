$bottom-line-color: rgba(128, 128, 128, 0.2);
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$header-color: #4f4f4f;
.project {
  width: 100%;
  padding: 1.5rem 3rem 5rem 3rem;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: 2rem;
  &::before {
    content: "";
    background: $bottom-line-color;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 90%;
  }
  .project_body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    .project_description {
      width: 30%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
    }
    .project_input {
      width: 70%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 2rem;
      .project_input_container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        position: relative;
        padding-bottom: 2rem;
        gap: 1rem;
        .sequence_number {
          position: absolute;
          top: 0;
          left: -1.5rem;
        }
        .exp_tooltip {
          max-width: 300px !important;
          background-color: var(--background-color) !important;
          box-shadow: var(--primary-box-shadow) !important;
          border-radius: 5px !important;
          padding: 1rem !important;
          color: black !important;
          text-align: center !important;
          line-height: 1.5 !important;
          opacity: 1 !important;
        }
        .drag_icon {
          position: absolute;
          top: 0;
          right: 0;
          width: 20px;
          height: 20px;
          .drag_handle {
            width: 100%;
            height: 100%;
            color: grey;
            cursor: pointer;
          }
        }
        &::before {
          content: "";
          background: $bottom-line-color;
          position: absolute;
          bottom: 0;
          height: 1px;
          width: 90%;
        }
        .project_input_date {
          .myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
            border-color: green;
          }

          .myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
            border-color: green;
          }
          label {
            color: $header-color;
          }
        }
        .project_input_row {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: row;
        }
        .project_input {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          label {
            width: 100%;
            color: $label-color;
          }
          input {
            border: 1px solid $border-color-required;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            background-color: transparent;

            font-size: 1rem;
            font-weight: 400;
            color: $header-color;
            outline: none;
            box-shadow: none;
            transition: var(--primary-transition);
            &:focus {
              box-shadow: 0 0 10px $border-color-required;
            }
            &:valid {
              border: 1px solid $input-entered;
              &:focus {
                box-shadow: 0 0 10px $input-entered;
              }
            }
          }
          textarea {
            width: 100%;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            outline: none;
            background: transparent;
            border: 1.5px solid $input-entered;
            resize: none;
            color: var(--color-white);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .project {
    .project_body {
      width: 100%;
      flex-direction: column;
      .project_description {
        width: 100%;
      }
      .project_input {
        width: 100%;
        .project_input_container {
          .project_input_row {
            flex-direction: column;
          }
          .project_input {
            input {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
