$shadow: rgb(72 84 159 / 10%) 0px 10px 10px -10px;
$primaryColor: #008db9;
$hoverColor: #0dcaf0;
$buttonHover: #0dcaf044;
.invoiceDetails_header {
  width: 100%;
  padding: 1.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: $shadow;
  .invoiceDetails_header_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    .invoice_element_status {
      width: 8rem;
      height: 2.8rem;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      font-weight: 600;
      .invoice_element_status_dot {
        width: 7px;
        height: 7px;
        border-radius: 50%;
      }
    }
  }
  .invoiceDetails_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
    .editButton {
      width: initial;
      border: none;
      border-radius: 10rem;
      padding: 1rem 1.5rem;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.25;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
      outline: none;
      background: rgb(249, 250, 254);
      color: $primaryColor;
      transition: var(--primary-transition);
      &:hover {
        background: $buttonHover;
      }
    }
    .deleteButton {
      width: initial;
      border: none;
      border-radius: 10rem;
      padding: 1rem 1.5rem;
      color: white;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.25;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
      outline: none;
      background: rgb(236, 87, 87);
      transition: var(--primary-transition);
      &:hover {
        background: red;
      }
    }
    .pdfButton {
      width: initial;
      border: none;
      border-radius: 10rem;
      padding: 1rem 1.5rem;
      color: white;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.25;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
      outline: none;
      background: $primaryColor;
      transition: var(--primary-transition);
      &:hover {
        background: $hoverColor;
      }
    }
  }
}
.paid {
  background-color: #f3fcf9;
}
.paid_dot {
  background-color: green;
}
.pending {
  background-color: #fcfaf3;
}
.pending_dot {
  background-color: orangered;
}
.declined {
  background-color: #fcf3f3;
}
.declined_dot {
  background-color: red;
}
@media screen and (max-width: 650px) {
  .invoiceDetails_header {
    flex-direction: column;
    gap: 2rem;
  }
}
