$text-color: #6e6d70;
.employee_card_container_header {
  width: 100%;
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1.5rem;
  position: relative;
  .search_icon {
    height: 100%;
    width: 2rem;
    color: $text-color;
  }
  .employee_search_input {
    width: 80%;
    height: 100%;
    color: $text-color;
    border: none;
    outline: none;
    border-radius: 6px;
    background-color: transparent;
    font-size: 16px;
    transition: var(--primary-transition);
  }
  .clicked {
    background-color: var(--primary-color);
    color: white;
  }
}

@media screen and (max-width: 650px) {
  .employee_card_container_header {
    .search_icon {
      display: none;
    }
  }
}

@media screen and (min-width: 750px) and (max-width: 900) {
  .employee_card_container_header {
    .employee_search_input {
      width: 70%;
    }
  }
}
