.customerBackground {
  width: 140px;
  height: 68px;
  position: absolute;
  bottom: -20px;
  left: -4.2%;
  text-align: center;
  background-image: url("../../../assets/images/Subtract.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transition: var(--primary-transition);
}
.customerBackground2 {
  width: 140px;
  height: 68px;
  position: absolute;
  bottom: -20px;
  left: -6.8%;
  text-align: center;
  background-image: url("../../../assets/images/Subtract.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transition: var(--primary-transition);
}
