.kodiva_widget {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  padding: 15px;
  box-shadow: var(--primary-box-shadow);
  border-radius: 10px;
  background-color: var(--primary-color);
  gap: 10px;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 230px;
  .kodiva_widget_logo {
    width: 80px;
    height: 80px;
    padding: 20px;
    border-radius: 50%;
    background-color: rgba(201, 201, 201, 0.205);
    .logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  p {
    color: white;
    font-size: 12px;
    padding-top: 10px;
  }
  h3 {
    color: white;
    font-weight: 500;
    font-size: 16px;
  }
}
@media screen and (max-width: 850px) {
  .kodiva_widget {
    width: 200px;
  }
}
