.notification {
  width: 20%;
  height: 4rem;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 19px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  font-weight: 600;
  transition: var(--primary-transition);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.07),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}
.error {
  background: rgba(255, 0, 0, 0.55);
}
.successfull {
  background: rgba(0, 255, 0, 0.55);
}
@media screen and (max-width: 1400px) {
  .notification {
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .notification {
    width: 70%;
  }
}
