.view_button {
  background-color: var(--primary-color);
  transition: var(--primary-transition);
  padding: 0.6rem 1.3rem;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: var(--primary-box-shadow);
  cursor: pointer;
  color: white;
  font-weight: 500;
  &:hover {
    background-color: var(--hover-color);
    scale: 1.1;
  }
}
