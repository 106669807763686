.gdpr_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .gdpr_container_body {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 4rem;
    box-shadow: var(--primary-box-shadow);
    border-radius: 0.5rem;
    gap: 1.5rem;
    padding-bottom: 4rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .gdpr_container {
    .gdpr_container_body {
      margin-top: 2rem;
      width: 100%;
      overflow-x: scroll;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1450px) {
  .gdpr_container {
    .gdpr_container_body {
      width: 100%;
    }
  }
}
