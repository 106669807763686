@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
$dark-color: #003d50;
.user_dashboard_calendar {
  width: 25%;
  height: calc(100vh - 147.2px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #008eb9;
  border-radius: 30px;
  padding: 1.5rem;
  color: white;
  font-family: "Raleway", sans-serif;
  overflow-y: scroll;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  cursor: pointer;
  position: relative;
  h5 {
    font-family: "Raleway", sans-serif;
    color: white;
    letter-spacing: 0.03em;
  }
  .event_date {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    color: $dark-color;
    font-weight: 800;
    letter-spacing: 0.03em;
    p {
      color: white;
      font-weight: 500;
    }
    .events {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      .event_element {
        background-color: white;
        padding: 0.5rem;
        border-radius: 18px;
        color: black;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        h4 {
          font-family: "Raleway", sans-serif;
          color: var(--hover-color);
          letter-spacing: 0.03em;
          font-weight: 700;
        }
        span {
          color: gray;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .user_dashboard {
    .user_dashboard_calendar {
      width: 100%;
      height: 100%;
    }
  }
}
