@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
$dark-color: #003d50;
.user_dashboard_small_widget {
  background-color: white;
  padding: 1.5rem;
  width: 100%;
  height: 9.75rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 40px;
  h4 {
    font-size: 22px;
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    letter-spacing: 0.03em;
  }
  .pending_invoice_number {
    font-size: 42px;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    color: $dark-color;
  }
  span {
    color: $dark-color;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
  }
  .widget_icon {
    font-size: 45px;
    color: $dark-color;
  }
}
@media screen and (max-width: 650px) {
  .user_dashboard_small_widget {
    width: 100%;
  }
}
