.customEvent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 3px;
  .customEvent_time {
    font-size: 10px;
  }
  .customEvent_text {
    display: block;
    width: 100%;
    word-wrap: break-word;
    font-size: 13px;
    font-weight: bold;
  }
}
