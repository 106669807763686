@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
$dark-color: #003d50;
.userDashboardScrollableWidget {
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4rem;
  background-color: white;
  width: 14rem;
  height: 14rem;
  max-height: 14rem;
  border-radius: 45px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  transition: 0.2s all;
  .page {
    width: 100%;
    height: 98%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    scroll-snap-align: start;
    position: relative;
    .page_indicator {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 0.2rem;
      .page_indicator_element {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        cursor: pointer;
      }
      .active {
        background-color: $dark-color;
      }
      .inactive {
        background-color: #003d503b;
      }
    }
  }
  .first {
    position: relative;
    .page_indicator {
      top: 0;
    }
  }
  .widget_icon {
    font-size: 70px;
    color: $dark-color;
  }
  p {
    font-size: 26px;
    color: $dark-color;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
  }
  .your_rate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    color: $dark-color;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    .rate_value {
      color: var(--primary-color);
      font-weight: 600;
      font-family: "Raleway", sans-serif;
      font-size: 16px;
    }
  }
  span {
    color: $dark-color;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }
  .next_rate {
    color: var(--primary-color);
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    font-size: 20px;
  }
  .kodiva_office {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
    scale: 1.15;
    cursor: pointer;
  }
}
@media screen and (max-width: 1050px) {
  .user_dashboard_widget {
    width: 11.1rem;
    padding: 0.9rem 1.1rem;
    p {
      font-size: 22px;
    }
    span {
      font-size: 14px;
    }
  }
}

.userDashboardScrollableWidget::-webkit-scrollbar {
  display: none;
}
