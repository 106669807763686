.filter_view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  .filter_option {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.5rem;
    // background-color: #f7f7f7;
    background-color: white;
    padding: 0.5rem;
    border-radius: 8px;
    .filter_option_name,
    .filter_option_option {
      font-size: 14px;
    }
    .filter_option_name {
      color: #57565b;
    }
    .filter_option_option {
      color: #9896a2;
    }
    .cross {
      transition: var(--primary-transition);
      font-weight: bold;
      cursor: pointer;
    }
    transition: var(--primary-transition);
    &:hover {
      scale: 1.05;
      .cross {
        color: red;
      }
    }
  }
  .add_filter {
    display: flex;
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
    // background-color: #f7f7f7;
    background-color: white;
    align-items: center;
    justify-content: center;

    position: relative;
    .add {
      font-size: 2rem;
      color: #37363f;
      transition: var(--primary-transition);
      cursor: pointer;
      &:hover {
        scale: 1.1;
        color: var(--primary-color);
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .filter_view {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
