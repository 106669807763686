.filteroption {
  position: absolute;
  width: 18rem;
  height: 12rem;
  background-color: #f2f2f2;
  box-shadow: var(--primary-box-shadow);
  z-index: 2;
  padding: 1rem 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.4rem;
  .filteroption_container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .filteroption_radio_section {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 0.4rem;
      .filteroption_row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        input {
          height: 17px;
          width: 17px;
        }
      }
      .filteroption_value_field {
        width: 100%;
        outline: none;
        border-radius: 5px;
        border: 2px solid var(--primary-color);
        padding: 0.3rem 0.5rem;
        background-color: transparent;
      }
    }
  }
  .filteroption_radio_buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
.halfpassed {
  left: -19rem !important;
  top: 3rem !important;
}
.nothalfpassed {
  top: 2.3rem !important;
  left: 9rem !important;
}
@media screen and (max-width: 650px) {
  .filteroption {
    padding: 1rem;
    width: 11rem;
  }
  .halfpassed {
    left: -11.5rem !important;
    top: 3rem !important;
  }
  .nothalfpassed {
    top: 2.3rem !important;
    left: 6.5rem !important;
  }
}
