$header-color: #4f4f4f;
$label-color: rgba(0, 0, 0, 0.6);
$border-color: #e6e6e6;
.invoiceList {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  .add_invoice {
    width: 100%;
    padding: 0.6rem 0;
    border-radius: 25px;
    border: none;
    background-color: #f9fafe;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    color: $label-color;
    transition: var(--primary-transition);
    &:hover {
      background-color: var(--primary-color);
      color: white;
    }
  }
  .invoiceList_element {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .invoiceList_element_field {
      display: flex;
      flex-direction: column;
      gap: 5px;
      label {
        width: 100%;
        color: $label-color;
        font-size: 13px;
      }
      input {
        border: 1px solid $border-color;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background-color: transparent;

        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .invoiceList {
    .invoiceList_element {
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding-bottom: 10px;
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border-radius: 4px;
        background-color: $border-color;
      }
      .invoiceList_element_field {
        width: 100%;
        input {
          width: 100% !important;
        }
      }
    }
  }
}
