.invoiceDetails_body_header {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  .invoiceDetails_body_header_address {
    text-align: right;
  }
}
.invoiceDetails_body_info {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  .invoiceDetails_body_info_dates {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.35rem;
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .invoiceDetails_body_info{
    flex-direction: column;
  }
}
