.settings {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .settings_container {
    padding: 3rem 8rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background: white;
    background-clip: content-box;
    border-radius: 8px;
    gap: 4rem;
    .settings_container_body {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0.5rem;
      width: 100%;
      background-color: white;
      border-radius: 8px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      h2 {
        width: 100%;
        text-align: center;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: -0.5rem;
          height: 1px;
          width: 100%;
          background-color: #e6e6e6;
        }
      }
      .settings_container_body_elements {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .settings {
    .settings_container {
      padding: 3rem 0.5rem;
    }
  }
}
