.home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  .home_candidates {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .home_header {
    padding: 0 3rem 0 3rem;
    height: 92px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    position: relative;
  }
  .home_table {
    width: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: var(--primary-box-shadow);
    border-radius: 0.5rem;
    gap: 1.5rem;
  }
  .home_pagination {
    width: 100%;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 2rem;
    position: sticky;
    left: 0;
    .home_pagination_select {
      position: absolute;
      left: 0px;
    }
    .home_candidate_info {
      padding: 10px;
      position: absolute;
      right: 0;
    }
  }
}
.settings_mod {
  top: 0;
  left: 0;
  position: absolute;
}
.MuiInputBase-inputSizeSmall {
  margin-top: 5px;
}
@media screen and (max-width: 650px) {
  .home{
    .home_table{
      .home_pagination{
        .pagination{
          margin-left: 1.5rem!important;
          width: 60%!important;
        }
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  .home {
    gap: 0;
    .home_candidates {
      padding-left: 2rem;
      padding-top: 2rem;
    }
    .home_header {
      padding-top: 8rem;
      flex-direction: column;
      gap: 2rem;
    }
    .home_table {
      margin-top: 2rem;
      width: 100%;
      overflow-x: scroll;
      .home_pagination {
        .pagination {
          margin-left: 5.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1450px) {
  .home {
    .home_candidates {
      padding-top: 2rem;
      padding-left: 2rem;
    }
    .home_table,
    .home_candidates {
      width: 100%;
    }
  }
}
