@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
:root {
  --background-color: #f2f2f2;
  --primary-color: #008db9;
  --hover-color: #0dcaf0;
  --primary-transition: all 250ms ease-in-out;
  --primary-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.07),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --primary-border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}
body {
  background: var(--background-color);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
}
