.kodiva_loading {
  stroke-dasharray: 700;
  stroke-dashoffset: 700;
  pointer-events: none;
  animation: animateDash 2s linear forwards infinite;
}

@keyframes animateDash {
  to {
    stroke-dashoffset: 100;
  }
}
