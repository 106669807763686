.widget {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 250px;
  padding: 15px;
  box-shadow: var(--primary-box-shadow);
  border-radius: 10px;
  background-color: var(--primary-color);
  gap: 10px;
  flex-direction: column;
  height: 150px;
  .widget_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .widget_employee_img {
      width: 80px;
      height: 80px;
      padding: 20px;
      .employee_img {
        width: 100%;
        height: 100%;
      }
    }
    .number {
      color: black;
      font-size: 28px;
      font-weight: 500;
    }
  }
  .text {
    padding-left: 20px;
    color: black;
    font-size: 14px;
    font-weight: 600;
  }
}
@media screen and (max-width: 900px) {
  .widget {
    width: 160px;
  }
}
