.invoices_header_filter {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  flex-wrap: wrap;
  .invoice_header_filter_date {
    position: relative;
    cursor: pointer;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    width: 157px;
    height: 42px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    transition: var(--primary-transition);
    &:hover {
      border: 2px solid var(--hover-color);
    }
    .invoice_header_filter_date_text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      label {
        position: absolute;
        top: -0.75rem;
        left: 5px;
        background: linear-gradient(
          to top,
          rgb(255, 255, 255) 0%,
          rgb(255, 255, 255) 50%,
          var(--background-color) 50%,
          var(--background-color) 100%
        );
        font-size: 12px;
        color: var(--primary-color);
      }
    }
  }
  .invoices_header_filter_choose {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    cursor: pointer;
    position: relative;
    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background-color: #008db9;
      height: 2px;
      border-radius: 4px;
      scale: 0 1;
      transform-origin: left;
      transition: scale 0.5s;
    }
    &:hover {
      &::before {
        scale: 1;
      }
    }
  }
  .invoice_button_icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
