$button-color: #008db9;
$hover-color: #0dcaf0;
$primary-transition: all 250ms ease-in-out;
.addInvoice_button {
  padding: 15px;
  height: 3rem;
  width: 10.5rem;
  border-radius: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $button-color;
  font-size: 14px;
  color: white;
  font-weight: bold;
  letter-spacing: 0.05em;
  border: none;
  cursor: pointer;
  transition: $primary-transition;
  &:hover {
    background-color: $hover-color;
    scale: 1.05;
  }
}
