.invoices {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  .invoices_container {
    padding: 2rem 8rem;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 4rem;
    .invoices_header {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .invoice_body {
      width: 100%;
      display: flex;
      align-self: flex-start;
      justify-items: flex-start;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
.invoices_mod {
  top: -70px;
  left: 0;
  position: absolute;
}
@media screen and (max-width: 650px) {
  .invoices {
    .invoices_container {
      padding: 2rem 1rem;
      .invoices_header {
        flex-direction: column;
        gap: 2rem;
      }
    }
    .invoices_mod {
      width: 100%;
    }
  }
}
@media screen and (min-width: 651px) and (max-width: 1000px) {
  .invoices {
    .invoices_container {
      padding: 2rem;
    }
  }
}
