$bottom-line-color: rgba(128, 128, 128, 0.2);
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$header-color: #4f4f4f;
$dark-color: #003d50;
.deleteCandidate_modal {
  .candidate_info_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .setting_header {
      font-size: 21px;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      position: relative;
      cursor: pointer;
      color: $dark-color;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 90%;
        height: 1px;
        background-color: #e6e6e6;
      }
    }
  }
  .deleteCandidate_modal_container {
    h2 {
      padding-bottom: 1rem;
    }
    form {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 0.5rem;
      overflow: scroll;
      .user_input_row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
        .user_input_row_column {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          gap: 1rem;
          .user_input_row_column_header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        .tooltip_input {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
        }
        .user_input {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          .projectRate_element {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            gap: 1rem;
          }
          label {
            width: 100%;
            color: $label-color;
            font-size: 14px;
          }
          input {
            border: 1px solid $border-color-required;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            background-color: transparent;

            font-size: 1rem;
            font-weight: 400;
            color: $header-color;
            outline: none;
            box-shadow: none;
            transition: var(--primary-transition);
            &:focus {
              box-shadow: 0 0 10px $border-color-required;
            }
            &:valid {
              border: 1px solid $input-entered;
              &:focus {
                box-shadow: 0 0 10px $input-entered;
              }
            }
          }
          .disabled_next_rate {
            background-color: rgba(117, 117, 117, 0.17);
            border: 1px solid var(--primary-color);
            color: black;
            font-weight: bolder;
          }
          .disabled_rate {
            background-color: rgba(117, 117, 117, 0.17);
            color: var(--primary-color);
            font-weight: bolder;
            border: 1px solid transparent;
          }
          select {
            border: 1px solid $border-color-required;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            background-color: transparent;

            font-size: 1rem;
            font-weight: 400;
            color: $header-color;
            outline: none;
            box-shadow: none;
            transition: var(--primary-transition);
            &:focus {
              box-shadow: 0 0 10px $border-color-required;
            }
            &:valid {
              border: 1px solid $input-entered;
              &:focus {
                box-shadow: 0 0 10px $input-entered;
              }
            }
          }
        }
      }
    }
  }
}
.updateUser {
  .updateUser_container {
    padding: 2rem 3.5rem;
  }
}

@media screen and (max-width: 650px) {
  .updateUser {
    height: 100vh;
    width: 100%;
    overflow: scroll;
    .candidate_info_header {
      .setting_header {
        font-size: 19px;
      }
    }
    .updateUser_container {
      form {
        .user_input_row {
          flex-direction: column;
          input {
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 651px) and (max-width: 850px) {
  .updateUser {
    width: 95%;
    height: 95%;
  }
}
@media screen and (min-width: 651px) and (max-width: 1400px) {
  .updateUser {
    overflow: scroll;
    height: 90%;
    width: 80%;
  }
}
