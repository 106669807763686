.background {
  width: 140px;
  height: 68px;
  position: absolute;
  bottom: -20px;
  left: -1.8%;
  text-align: center;
  background-image: url("../../../assets/images/Subtract.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transition: var(--primary-transition);
}
.background2 {
  width: 140px;
  height: 68px;
  position: absolute;
  bottom: -20px;
  left: 12.7%;
  text-align: center;
  background-image: url("../../../assets/images/Subtract.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transition: var(--primary-transition);
}
.background3 {
  width: 140px;
  height: 68px;
  position: absolute;
  bottom: -20px;
  left: 27.9%;
  text-align: center;
  background-image: url("../../../assets/images/Subtract.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transition: var(--primary-transition);
}
.background4 {
  width: 135px;
  height: 68px;
  position: absolute;
  bottom: -20px;
  left: 41.7%;
  text-align: center;
  background-image: url("../../../assets/images/Subtract.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transition: var(--primary-transition);
}
.background5 {
  width: 140px;
  height: 68px;
  position: absolute;
  bottom: -20px;
  left: 53.4%;
  text-align: center;
  background-image: url("../../../assets/images/Subtract.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transition: var(--primary-transition);
}
.background6 {
  width: 140px;
  height: 68px;
  position: absolute;
  bottom: -20px;
  left: 68.5%;
  text-align: center;
  background-image: url("../../../assets/images/Subtract.svg");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  transition: var(--primary-transition);
}
