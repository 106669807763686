$bottom-line-color: rgba(128, 128, 128, 0.2);
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$header-color: #4f4f4f;
.changeUserSettings {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-color: #e6e6e6;
    height: 100%;
    width: 1px;
    left: 0;
    top: 0;
  }
  form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .changeUserSettings_container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 1rem;
      .user_input_row_column {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 1rem;
        .user_input_row_column_header {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .user_input {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          gap: 0.5rem;
          .projectRate_element {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            gap: 1rem;
          }
          label {
            width: 100%;
            color: $label-color;
            font-size: 14px;
          }
          input {
            border: 1px solid $border-color-required;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            background-color: transparent;

            font-size: 1rem;
            font-weight: 400;
            color: $header-color;
            outline: none;
            box-shadow: none;
            transition: var(--primary-transition);
            &:focus {
              box-shadow: 0 0 10px $border-color-required;
            }
            &:valid {
              border: 1px solid $input-entered;
              &:focus {
                box-shadow: 0 0 10px $input-entered;
              }
            }
          }
          .disabled_next_rate {
            background-color: rgba(117, 117, 117, 0.17);
            border: 1px solid var(--primary-color);
            font-weight: bolder;
            color: black;
          }
          .disabled_rate {
            background-color: rgba(117, 117, 117, 0.17);
            font-weight: bolder;
            color: var(--primary-color);
            border: 1px solid transparent;
          }
          input:disabled {
            background-color: rgba(117, 117, 117, 0.17);
            border: none;
          }

          select {
            border: 1px solid $border-color-required;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            background-color: transparent;

            font-size: 1rem;
            font-weight: 400;
            color: $header-color;
            outline: none;
            box-shadow: none;
            transition: var(--primary-transition);
            &:focus {
              box-shadow: 0 0 10px $border-color-required;
            }
            &:valid {
              border: 1px solid $input-entered;
              &:focus {
                box-shadow: 0 0 10px $input-entered;
              }
            }
          }
        }
      }
      .setting_header {
        font-size: 21px;
        margin-top: 1rem;
        padding-bottom: 0.5rem;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 90%;
          height: 1px;
          background-color: #e6e6e6;
        }
      }
      .changeUserSettings_container_row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        .changeUserSettings_container_row_column {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          gap: 0.5rem;

          label {
            width: 100%;
            color: $label-color;
            font-size: 14px;
          }
          input {
            border: 1px solid $border-color-required;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            background-color: transparent;

            font-size: 1rem;
            font-weight: 400;
            color: $header-color;
            outline: none;
            box-shadow: none;
            transition: var(--primary-transition);
            &:focus {
              box-shadow: 0 0 10px $border-color-required;
            }
            &:valid {
              border: 1px solid $input-entered;
              &:focus {
                box-shadow: 0 0 10px $input-entered;
              }
            }
          }
          .disabled_next_rate {
            background-color: rgba(117, 117, 117, 0.17);
            border: 1px solid var(--primary-color);
            font-weight: bolder;
            color: black;
          }
          .disabled_rate {
            background-color: rgba(117, 117, 117, 0.17);
            font-weight: bolder;
            color: var(--primary-color);
            border: 1px solid transparent;
          }
          input:disabled {
            background-color: rgba(117, 117, 117, 0.17);
            border: none;
          }
          svg {
            transition: var(--primary-transition);
            &:hover {
              color: var(--hover-color);
              scale: 1.15;
              cursor: pointer;
            }
          }
        }
      }
    }
    .update_account_settings {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 1rem;
    }
  }
}
@media screen and (min-width: 651px) and (max-width: 850px) {
  .changeUserSettings {
    form {
      .changeUserSettings_container {
        .changeUserSettings_container_row {
          flex-direction: row;
          gap: 0.5rem;
          input {
            width: 85% !important;
          }
        }
      }
      .changeUserSettings_container :nth-child(9) {
        .changeUserSettings_container_row_column {
          input {
            width: 41.5% !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .changeUserSettings {
    form {
      .changeUserSettings_container {
        .changeUserSettings_container_row {
          flex-direction: column;
          gap: 0.5rem;
          input {
            width: 100% !important;
          }
        }
      }
    }
  }
}
