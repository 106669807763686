.filter_container {
  position: absolute;
  border: 1px solid grey;
  outline: none;
  border-radius: 7px;
  padding: 4px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 2;
  top: 25px;
}
@media screen and (max-width: 1200px) {
  .filter_container {
    padding: 1rem 1rem;
    left: -1.5rem;
  }
}
