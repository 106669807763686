.cancelFilter_button {
  width: 28%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: black;
  letter-spacing: 0.04em;
  background-color: #f7f7f7;
  padding: 0.3rem 0.5rem;
  transition: var(--primary-transition);
  &:hover {
    scale: 1.05;
  }
}
@media screen and (max-width: 650px) {
  .cancelFilter_button {
    font-size: 13px;
  }
}
