.empoloyee_card {
  width: 17rem;
  height: 22.5rem;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  transition: var(--primary-transition);
  &:hover {
    scale: 1.05;
  }
  .employee_card_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    gap: 1rem;
    .employee_card_header_availability {
      font-size: 14px;
      padding: 0.5rem 1rem;
      border-radius: 8px;
    }
    .employee_forward_icon {
      cursor: pointer;
      transition: var(--primary-transition);
      color: var(--primary-color);
      &:hover {
        color: var(--hover-color);
        scale: 1.35;
      }
    }
  }
  .employee_card_img {
    width: 100%;
    height: 38%;
    .person_icon {
      width: 100%;
      height: 100%;
    }
  }
  .employee_card_text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .name {
      color: black;
      font-weight: 600;
      letter-spacing: 0.04em;
      font-size: 17px;
    }
    .position {
      color: var(--primary-color);
      font-weight: 500;
      letter-spacing: 0.04em;
    }
  }
  .employee_card_description {
    margin-top: 0.5rem;
    padding: 0.5rem;
    width: 100%;
    border-radius: 8px;
    background: rgb(249, 250, 254);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .employee_card_description_row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .description {
        color: var(--hover-color);
        font-weight: 400;
        letter-spacing: 0.04em;
      }
    }
  }
}
.available {
  background-color: #f3fcf9;
}
.unavailable {
  background-color: #fcfaf3;
}
