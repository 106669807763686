.loading {
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, 50%);
}
@media screen and (max-width: 650px) {
  .loading {
    left: 40%;
  }
}
