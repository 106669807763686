.customDatePicker {
  width: 330px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.5rem;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 999;
  .date_picker {
    .css-15fehp8-MuiTypography-root-PrivatePickersMonth-root.Mui-selected {
      color: black;
      background-color: transparent !important;
      transition: var(--primary-transition);
      &:hover {
        color: white;
        background-color: var(--hover-color) !important;
      }
    }
  }
  .css-u0soqy-MuiPickerStaticWrapper-root {
    height: 260px;
    max-height: 260px;
    .css-15fehp8-MuiTypography-root-PrivatePickersMonth-root.Mui-selected,
    .css-3eghsz-PrivatePickersYear-button.Mui-selected {
      background-color: var(--primary-color);
    }
    .css-1sy1a1q-MuiTypography-root-PrivatePickersMonth-root,
    .css-3eghsz-PrivatePickersYear-button.Mui-selected {
      transition: var(--primary-transition);
      &:hover {
        color: white;
        background-color: var(--hover-color);
      }
    }
    .css-hlj6pa-MuiDialogActions-root {
      display: none;
    }
    .css-3eghsz-PrivatePickersYear-button {
      transition: var(--primary-transition);
      &:hover {
        color: white;
        background-color: var(--hover-color);
      }
    }
  }
  .customDatePicker_all {
    display: flex;
    background-color: var(--primary-color);
    align-items: center;
    justify-content: center;
    color: white;
    width: 103.33px;
    height: 36px;
    margin-right: 5px;
    border-radius: 20px;
    cursor: pointer;
    transition: var(--primary-transition);
    &:hover {
      scale: 1.05;
      background-color: var(--hover-color);
    }
  }
}
