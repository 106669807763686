$shadow: rgb(72 84 159 / 10%) 0px 10px 10px -10px;
$primaryColor: #008db9;
$hoverColor: #0dcaf0;
$buttonHover: #0dcaf044;
.invoiceDetails {
  width: 100%;
  .invoiceDetails_container {
    width: 100%;
    padding: 4rem 15rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    .invoice_backButton {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
      cursor: pointer;
      .backButtonIcon {
        color: $primaryColor;
        transition: var(--primary-transition);
      }
      p {
        color: $primaryColor;
        transition: var(--primary-transition);
      }
      &:hover {
        .backButtonIcon {
          color: $hoverColor;
        }
        p {
          color: $hoverColor;
        }
      }
    }
  }
}
.text {
  color: rgb(136, 142, 176);
  font-size: 0.85rem;
  line-height: 1.3;
  letter-spacing: 0.45px;
}
.paid {
  background-color: #f3fcf9;
}
.paid_dot {
  background-color: green;
}
.pending {
  background-color: #fcfaf3;
}
.pending_dot {
  background-color: orangered;
}
.declined {
  background-color: #fcf3f3;
}
.declined_dot {
  background-color: red;
}
.invoices_detail_mod {
  top: 0;
  left: 0;
  position: absolute;
}
@media screen and (max-width: 650px) {
  .invoiceDetails {
    .invoiceDetails_container {
      padding: 2rem 1rem;
    }
  }
  .text {
    font-size: 0.6875rem;
  }
  .invoices_detail_mod {
    width: 100%;
  }
}
@media screen and (min-width: 651px) and (max-width: 1000px) {
  .invoiceDetails {
    .invoiceDetails_container {
      padding: 4rem;
    }
  }
}
