$border: rgba(128, 128, 128, 0.2);
$header-color: #757575;
.settings_modal {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  width: 25rem;
  left: 0;
  top: 0;
  background: transparent;
  border-radius: 16px;
  padding: 2rem 1rem;
  z-index: 4;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  .settings_modal_container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    h2 {
      margin-bottom: 1.5rem;
    }
    .settings_modal_row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      input {
        border: 1px solid $border;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background-color: transparent;

        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
        transition: var(--primary-transition);
      }
      input[type="checkbox"] {
        height: 20px;
        width: 20px;
        border: 1px solid $border;
        border-radius: 0.25rem;
        background-color: transparent;

        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
        transition: var(--primary-transition);
      }
    }
  }
  .settings_modal_buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
}
