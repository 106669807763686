@import "react-big-calendar/lib/sass/styles";
.dashboard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .settings_mod {
    top: 0;
    left: 0;
    position: absolute;
  }
  .dashboard_container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    padding: 0.3rem 1rem 0 1rem;
    .widget_container {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 2rem;
      position: relative;
      .widgets {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        padding: 20px;
        gap: 20px;
        height: 300px;
      }
      .second_row{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
        .second_row_widgets{
          width: 70%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 20px;
          flex-direction: row;
          flex-wrap: wrap;
          .chart_widget {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            background-color: white;
            height: 275px;
            border-radius: 10px;
            margin-left: 20px;
            width: 380px;
            p {
              padding: 0.5rem 0;
            }
          }
        }
      }
    }
    .scheduler {
      position: relative;
      border-radius: 10px;
      width: 750px;
      color: black;
      font-weight: 400;
      .calendar {
        height: 750px;
        width: 100%;
        .rbc-toolbar {
          width: 100%;
          justify-content: flex-end;
          flex-direction: row-reverse;
          padding-left: 5rem;
          gap: 1rem;
          margin-bottom: 2rem;
          position: relative;
          &:first-child {
            button {
              border: 0;
              outline: none;
            }
          }
          .rbc-toolbar-label {
            flex-grow: unset;
            font-size: 25px;
            font-weight: bold;
            position: relative;
          }
        }
        .rbc-toolbar > span:nth-child(3) {
          display: none;
        }
      }
      .rbc-btn-group :first-child {
        font-weight: bold;
        color: black;
        transition: var(--primary-transition);
        cursor: pointer;
        &:hover {
          background-color: transparent;
          scale: 1.1;
          color: var(--hover-color);
        }
        :active {
          background-color: transparent;
        }
      }
      .rbc-day-slot .rbc-event-label {
        display: none;
      }
      .rbc-day-slot .rbc-event-content {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          height: 100%;
          width: 1.5px;
          border-radius: 2px;
          background-color: var(--primary-color);
          top: 0;
          left: 0;
        }
      }
      .rbc-event {
        justify-content: flex-end;
      }
      .rbc-btn-group :nth-child(2),
      .rbc-btn-group :nth-child(3) {
        color: var(--primary-color);
        font-weight: bold;
        font-size: 20px;
        transition: var(--primary-transition);
        &:hover {
          background-color: transparent;
          scale: 1.3;
          color: var(--hover-color);
        }
      }
      .rbc-current-time-indicator {
        background-color: var(--primary-color);
        height: 1.35px;
        border-radius: 1.35px;
      }
      .css-fvtpii-MuiTypography-root {
        color: white;
      }
      .rbc-today {
        background-color: transparent;
      }

      .rbc-header + .rbc-today {
        button {
          span {
            color: var(--primary-color);
          }
        }
      }
      .rbc-time-view .rbc-time-gutter {
        padding-top: 5px;
        text-align: left;
      }
      .rbc-header {
        border: 0;
        white-space: normal;
        button {
          width: 90%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          pointer-events: none;
          span {
            width: min-intrinsic;
            width: -webkit-min-content;
            width: -moz-min-content;
            width: min-content;
            display: table-caption;
            display: -ms-grid;
            -ms-grid-columns: min-content;
            color: #787f89;
            font-size: 20px;
          }
        }
      }
      .rbc-timeslot-group {
        border-bottom: 0;
      }
      .rbc-timeslot-group :nth-child(2) {
        border-bottom: 1px solid #f8f8f8;
      }
      .rbc-time-content :first-child {
        .rbc-timeslot-group {
          .rbc-time-slot {
            color: rgba(0, 0, 0, 0.6);
            width: 81.89px;
            font-size: 13px;
            border-bottom: 0;
          }
        }
      }
      .rbc-time-content :not(:nth-child(2), :first-child) {
        .rbc-timeslot-group {
          .rbc-time-slot {
            border-left: 1px solid #f8f8f8;
          }
        }
      }
      .rbc-time-content {
        .rbc-time-column:not(:first-child) {
          background-color: white;
        }
      }
      .rbc-time-column :last-child {
        .rbc-timeslot-group :last-child {
          border-bottom-right-radius: 8px;
        }
      }
      .rbc-time-content > * + * > * {
        border-left: 0;
      }
      .rbc-time-content {
        border-top: 0;
      }
      .rbc-day-slot .rbc-time-slot {
        border-top: 0;
      }
      .rbc-time-view .rbc-allday-cell {
        display: none;
      }
      .rbc-time-view {
        border: none;
      }
      .rbc-time-header-content {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border-left: 0;
        background: #fcfbfc;
      }
      .scheduler_calendar {
        position: absolute;
        padding: 5px;
        top: 0;
        right: 4rem;
        cursor: pointer;
        color: var(--primary-color);
        transition: var(--primary-transition);
        &:hover {
          color: var(--hover-color);
        }
      }
    }
  }
}
@media screen and (min-width: 650px) and (max-width: 1400px) {
  .dashboard {
    .dashboard_container {
      .scheduler {
        .calendar {
          .rbc-toolbar {
            align-items: flex-start;
            flex-direction: column-reverse;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1150px) {
  .dashboard {
    .dashboard_container {
      flex-direction: column;
      .widget_container{
        .second_row{
          flex-direction: column;
          .second_row_widgets{
            width: 100%;
          }
        }
      }
      .scheduler {
        width: 780px;
        margin-top: 20px;
        padding-bottom: 20px;
        .calendar {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .dashboard {
    .dashboard_container {
      flex-direction: column;
      padding: 0;
      .widget_container{
        .second_row{
          flex-direction: column;
          .second_row_widgets{
            width: 100%;
            .chart_widget{
              width: 90%;
            }
          }
        }
      }
      .widget_container {
        .chart_widget {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          background-color: white;
          height: 300px;
          border-radius: 10px;
          margin-left: 20px;
          width: 360px;
          p {
            padding: 0.5rem 0;
          }
        }
      }
      .scheduler {
        margin-top: 20px;
        padding-left: 20px;
        width: 97%;
        .overlay {
          margin-top: 50px;
        }
        .calendar {
          width: 100%;
          .rbc-time-header {
            .rbc-label {
              display: none;
            }
          }
          // .rbc-time-content :first-child {
          //   display: none;
          // }
          .rbc-time-content {
            .rbc-time-gutter {
              display: none;
            }
          }
        }
      }
    }
  }
  .dashboard .dashboard_container .scheduler {
    min-width: 350px;
  }
}
