$backgroundColor: #f9f9f9;
$text-color: #4f4f4f;
.newCV_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $backgroundColor;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 1.5rem 3rem;
  border-bottom: var(--primary-border-bottom);
  .newCV_header_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    img {
      height: 40px;
      width: 40px;
      object-fit: contain;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 500;
      color: $text-color;
    }
  }
  .new_CV_header_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    .pdf_img {
      width: 34px;
      height: 34px;
      object-fit: contain;
    }
    .pdf_img_full {
      margin-top: 3px;
      width: 32px;
      height: 30px;
      object-fit: contain;
      cursor: pointer;
    }
    button {
      outline: none;
      border: none;
      background-color: transparent;
      svg {
        width: 34px;
        height: 34px;
      }
    }
  }
  .pdf {
    width: 33px;
    height: 32px;
    color: red;
    cursor: pointer;
  }
}
@media screen and (max-width: 600px) {
  .newCV_header {
    padding: 1.5rem 1rem;
  }
}
