.submit_button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 10rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  text-transform: uppercase;

  padding: 0.625rem 1.5rem 0.5rem;
  cursor: pointer;

  transition: var(--primary-transition);
  &:hover {
    background-color: var(--hover-color);
  }
}
