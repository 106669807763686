tbody {
  padding: 1rem;
  tr {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    td {
      text-align: left;
      padding: 1rem;
      font-size: 16px;
      .delete_svg {
        width: 20px;
        height: 20px;
      }
      &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: auto;
      }
    }
  }
}
.critical {
  background-color: rgba(255, 0, 0, 0.2);
}
.normal {
  background-color: rgba(255, 255, 0, 0.3);
}
.good {
  background-color: rgba(0, 255, 0, 0.2);
}
@media screen and (max-width: 1200px) {
  .tbody {
    padding: 0;
  }
}
