$header-color: #4f4f4f;
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$bottom-line-color: rgba(128, 128, 128, 0.2);

.basic_information {
  width: 100%;
  padding: 1.5rem 3rem 5rem 3rem;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  position: relative;
  gap: 30px;
  &::before {
    content: "";
    background: $bottom-line-color;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 90%;
  }
  .basic_information_description {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
  }
  .basic_information_inputs_container {
    width: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    .basic_information_email_input {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: row;
      .basic_information_input {
        input {
          width: 53% !important;
        }
      }
      .email_buttons {
        height: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 0.5rem;
        gap: 10px;
      }
    }
    .basic_information_input {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      label {
        width: 100%;
        color: $label-color;
      }
      input,
      select {
        border: 1px solid $border-color-required;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background-color: transparent;

        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
        transition: var(--primary-transition);
        &:focus {
          box-shadow: 0 0 10px $border-color-required;
        }
        &:valid {
          border: 1px solid $input-entered;
          &:focus {
            box-shadow: 0 0 10px $input-entered;
          }
        }
      }
      textarea {
        width: 100%;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        outline: none;
        background: transparent;
        border: 1.5px solid $input-entered;
        resize: none;
        color: var(--color-white);
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .basic_information {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .basic_information_description {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .basic_information_inputs_container {
      .basic_information_email_input {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        .basic_information_input {
          input {
            width: 100% !important;
          }
        }
      }
      width: 100%;
      .basic_information_input {
        input {
          width: 100% !important;
        }
      }
    }
  }
}
