$button-color: #f93154;
.removeExperience_button {
  padding: 0.625rem 1.5rem 0.5rem;
  background-color: $button-color;
  color: white;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5;
  transition: var(--primary-transition);

  &:hover {
    background-color: red;
  }
}
