.applyFilter_button {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: var(--primary-color);
  color: white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04em;
  cursor: pointer;
  transition: var(--primary-transition);
  &:hover {
    background-color: var(--hover-color);
    scale: 1.05;
  }
}
@media screen and (max-width: 650px) {
  .applyFilter_button {
    font-size: 13px;
  }
}
