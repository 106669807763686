@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");
$dark-color: #003d50;
.user_dashboard_kodiva_widget {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--primary-color);
  width: 14rem;
  height: 14rem;
  border-radius: 45px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .kodiva_widget_logo {
    width: 80px;
    height: 80px;
    padding: 20px;
    border-radius: 50%;
    background-color: rgba(201, 201, 201, 0.205);
    .logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  p {
    color: white;
    font-size: 16px;
    padding-top: 10px;
    font-family: "Raleway", sans-serif;
  }
  h3 {
    color: white;
    font-size: 20px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    letter-spacing: 0.03em;
  }
}
@media screen and (max-width: 1050px) {
  .user_dashboard_kodiva_widget {
    width: 11.1rem;
    padding: 0.9rem 1.1rem;
  }
}
