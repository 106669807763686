$header-color: #4f4f4f;
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$input-entered: green;
$bottom-line-color: rgba(128, 128, 128, 0.2);
.invoice_add {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
  width: 40rem;
  left: 0;
  top: 0;
  border-radius: 16px;
  padding: 2rem 1rem;
  z-index: 4;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  padding: 4rem 3rem;
  gap: 2rem;
  overflow-y: scroll;
  overflow-x: hidden;
  .invoice_add_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .basic_information_input {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      select {
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background-color: transparent;
        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
        transition: var(--primary-transition);
        &:valid {
          border: 1px solid $input-entered;
          &:focus {
            box-shadow: 0 0 10px $input-entered;
          }
        }
      }
    }
  }
  .invoice_add_form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
  }
}
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
@media screen and (max-width: 650px) {
  .invoice_add {
    width: 100%;
    padding: 1rem;
  }
}
