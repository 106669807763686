.settings_navigation {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  .active {
    background-color: var(--primary-color);
    color: white;
  }
  button {
    background-color: transparent;
    width: 100%;
    padding: 0.5rem;
    border-radius: 8px;
    border: none;
    color: var(--primary-color);
    transition: var(--primary-transition);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &:hover {
      background-color: var(--hover-color);
      color: white;
    }
  }
}

@media screen and (max-width: 650px) {
  .settings_navigation {
    padding: 1rem;
    button {
      span {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 651px) and (max-width: 900px) {
  .settings_navigation {
    padding: 1rem 0.5rem;
    button {
      gap: 7px;
      font-size: 12px;
      svg {
        width: 17px;
      }
    }
  }
}
