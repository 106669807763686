$header-color: #4f4f4f;
$text-color: #757575;
$label-color: rgba(0, 0, 0, 0.6);
$border-color-required: #f93154;
$input-entered: green;
$bottom-line-color: rgba(128, 128, 128, 0.2);
.gdpr {
  width: 100%;
  padding: 1.5rem 3rem 5rem 3rem;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  position: relative;
  gap: 30px;
  &::before {
    content: "";
    background: $bottom-line-color;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 90%;
  }
  .gdpr_description {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
  }
  .gdpr_inputs_container {
    width: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    .gdpr_input_date {
      .myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
        border-color: green;
      }

      .myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
        border-color: green;
      }
      label {
        color: $label-color;
      }
    }
    .gdpr_input {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 0.5rem;
      label {
        width: 100%;
        color: $label-color;
      }
      input[type="checkbox"] {
        height: 20px;
        width: 20px;
        border: 1px solid $border-color-required;
        border-radius: 0.25rem;
        background-color: transparent;

        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
        transition: var(--primary-transition);
        &:focus {
          box-shadow: 0 0 10px $border-color-required;
        }
        &:valid {
          border: 1px solid $input-entered;
          &:focus {
            box-shadow: 0 0 10px $input-entered;
          }
        }
      }
      input {
        border: 1px solid $header-color;
        padding: 0.375rem 0.75rem;
        border-radius: 0.25rem;
        background-color: transparent;

        font-size: 1rem;
        font-weight: 400;
        color: $header-color;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .gdpr {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .gdpr_description {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .gdpr_inputs_container {
      width: 100%;
      .gdpr_input {
        input[type="text"] {
          width: 100% !important;
        }
      }
    }
  }
}
