.filter_modal {
  position: absolute;
  outline: none;
  border-radius: 7px;
  padding: 4px;
  background: white;
  display: flex;
  justify-content: flex-start;
  padding: 1rem 2rem;
  align-items: flex-start;
  flex-direction: column;
  z-index: 2;
  top: 3.2rem;
  left: 0;
}
@media screen and (max-width: 1200px) {
  .filter_modal {
    padding: 1rem 1rem;
  }
}
