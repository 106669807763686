.gdpr_table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
}
@media screen and (max-width: 900px) {
  .gdpr_table {
    width: 100%;
    margin: auto;
  }
}
