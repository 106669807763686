.users {
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-color: #e6e6e6;
    height: 100%;
    width: 1px;
    left: 0;
    top: 0;
  }
  .users_box {
    width: 100%;
    height: 700px;
    flex-grow: 1;
    display: flex;
    .users_table {
      border: none;
      box-shadow: none;
      .users_table_row {
        width: 99.8%;
        border-radius: 8px;
        margin-bottom: 0.5rem;
        border: 1px solid transparent;
        outline: none;
        transition: var(--primary-transition);
        &:hover {
          background-color: transparent;
          border: 1px solid var(--hover-color);
          .edit_icon {
            color: var(--primary-color);
          }
          .delete_icon {
            color: #f93154;
          }
          .password_icon {
            color: black;
          }
        }
        .edit_icon {
          color: transparent;
          width: 17px;
          margin-left: 0.5rem;
          margin-right: 1rem;
          cursor: pointer;
          transition: var(--primary-transition);
          &:hover {
            color: var(--hover-color);
            scale: 1.2;
          }
        }
        .delete_icon {
          color: transparent;
          width: 17px;
          margin-left: 0.5rem;
          margin-right: 1rem;
          cursor: pointer;
          transition: var(--primary-transition);
          &:hover {
            color: red;
            scale: 1.2;
          }
        }
        .password_icon {
          color: transparent;
          width: 17px;
          margin-left: 0.5rem;
          margin-right: 1rem;
          cursor: pointer;
          transition: var(--primary-transition);
          &:hover {
            color: black;
            scale: 1.2;
          }
        }
        .exp_tooltip {
          max-width: 300px !important;
          background-color: var(--background-color) !important;
          box-shadow: var(--primary-box-shadow) !important;
          border-radius: 5px !important;
          padding: 1rem !important;
          color: black !important;
          text-align: center !important;
          line-height: 1.5 !important;
          opacity: 1 !important;
        }
      }
      .users_table_cell {
        outline: none;
      }
      .MuiDataGrid-columnHeaders {
        border: none;
      }
    }
  }
}
